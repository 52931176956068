export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'distribution-centers/edit/'
  },
  { text: 'Номер', value: 'id_portal', width: '150px' },
  { text: 'Название РЦ', value: 'name' },
  { text: 'Регион', value: 'region' },
  { text: 'Сеть', value: 'network' },
  { text: 'Бронирование, min', value: 'intermediate_booking_min_pallet', width: '150px' },
  { text: 'Значение оптимал.',
    value: 'consolidation_booking_soft_limit_pallet', width: '200px' },
  { text: 'Бронирование, max', value: 'intermediate_booking_max_pallet', width: '150px' }
  // { text: 'Консолид-ое бронирование, min', value: 'consolidation_booking_min_pallet', width: '150px' },
  // { text: 'Консолид-ое бронирование, max', value: 'consolidation_booking_max_pallet', width: '150px' }
]
