<template>
  <div class="rcc-table-list">
    <div v-if="!isSupplierRole" class="rcc-table-list__table-type-radio">
      <rcc-radio-group :options="tableTypeRadioOptions" v-model="tableType" />
    </div>

    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isShowFilters="false"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import distributionCenterApi from '@/api/distribution-center'
import { objectToOptions } from '@/utils/get-options'
import headerData from './header'

import RccRadioGroup from 'Components/controls/radio-group/rcc-radio-group.vue'
import RccTable from 'Components/table/rcc-table'

export default {
  mixins: [Page, TableList, distributionCenterApi],

  components: {
    RccTable,
    RccRadioGroup
  },

  data() {
    return {
      tableType: 'active'
    }
  },

  computed: {
    headerData() {
      return headerData
    },

    tableTypeRadioOptions() {
      return objectToOptions(
        { object: this.$constants.status, keyName: 'value', valueName: 'label' }
      )
    },

    getDcMethod() {
      return this.tableType === 'active' ? this.getDcs : this.getBlockedDcs
    },

    isSupplierRole() {
      return this.$store.user.role === this.$constants.users.supplier
    }
  },

  watch: {
    tableType() {
      this.page = 1
      this.loadData()
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getDcMethod({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(({ pages, items }) => {
          if (!pages) {
            this.resetTableData()
            return
          }

          this.pagesCount = pages
          this.rows = items
        })
        .finally(() => { this.isDataLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-table-list {
  &__table-type-radio {
    margin-bottom: 10px;
  }
}
</style>
